<template>
  <div>
    <Modal :closable="false" :mask-closable="false" :width="680" footer-hide class="camera"
      :class="{ emptyPic: !oldPics.length }" v-model="showCamera" title="病历扫描" @on-cancel="close">
      <div class="patientInfo">
        <span class="name">{{ cameraInfo2.info.patientName }}</span>
        <span>{{ cameraInfo2.info.sex }}</span>
        <span>{{ cameraInfo2.info.patientAge }}岁</span>
        <span>手机号:{{ cameraInfo2.info.cellphoneNo }}</span>
      </div>
      <Icon @click="close" class="modal_close" type="md-close" />
      <div class="camera_k">
        <div id="CameraCtl2" class="rotate_mk" :class="rotate" :style="{ width: 600 + 'px', height: 450 + 'px' }">
          <video ref="video2" class="canvas rotate" :width="width" :height="height" autoplay></video>
          <canvas ref="canvas" class="picture-canvas" :width="height" :height="width"></canvas>
        </div>
        <!-- 拍照 -->
        <div v-if="isStart" class="captureImage_btn" @click="takePhoto">
          <Tooltip content="拍照[F9]" placement="bottom">
            <Icon type="md-camera" />
          </Tooltip>
        </div>
      </div>
      <div ref="images" class="u_pics">
        <div class="pic_title" v-if="typeId">已拍手相</div>
        <div class="u_pic_k">
          <div class="u_pic" v-for="(item, index) in oldPics" :key="index + '_a'">
            <div class="img_k">
              <img v-if="/^data:/.test(item.picUrl)" @click="clickPic" :src="item.picUrl" />
              <img v-else @click="clickPic" :src="cloudPic +
      '/' +
      (typeId ? 'hand' : 'medical') +
      '/' +
      item.picUrl
      " />
            </div>
            <div v-if="isToday(item.crDt)" @click="delPic(item, index, true)" class="del_btn">
              <Icon type="ios-close-circle" />
            </div>
            <i class="date">{{ item.crDt }}</i>
          </div>
        </div>
      </div>
    </Modal>
    <Spin v-if="loading" class="u_spin ivu-spin-fix_c" fix>
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>Loading</div>
    </Spin>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import viewer from "viewerjs";
export default {
  computed: {
    ...mapGetters([
      "userInfo", // 用户信息
      "cameraInfo2"
    ]),
  },
  watch: {
    cameraInfo2(newValue, oldValue) {
      this.typeId = this.cameraInfo2.typeId;
      this.showCamera = this.cameraInfo2.showWin;
      if (newValue.info.registerCode != oldValue.info.registerCode) {
        this.newPics = [];
      }
      if (newValue.showWin) {
        this.init();
        this.getData();
      }
    }
  },

  data() {
    return {
      // rotate: 'rotate_0',
      rotate: 'rotate_90',
      MediaStreamTrack: null,
      imgData: null,
      video: null,
      canvas: null,
      isStart: false,
      // width: 3264,
      // height: 2448,
      // width: 1800,

      height: 1500,
      width: 1100,

      imgData: "",
      typeId: 0, //0.病历扫描 1.手相 2.面相
      loading: false,
      picView: null,

      camera: {},
      showCamera: false,
      newPics: [],
      oldPics: [],
    };
  },
  methods: {
    // 开始预览
    getMedia() {
      let constraints = {
        video: { width: this.width, height: this.height },
        audio: false,
      };

      let promise = navigator.mediaDevices.getUserMedia(constraints);
      promise.then((MediaStream) => {
        this.MediaStreamTrack = typeof MediaStream.stop === 'function' ? MediaStream : MediaStream.getTracks()[0];
        this.video.srcObject = MediaStream;
        this.video.play();
        this.isStart = true;
      });
    },
    // 初始化
    init() {
      this.isStart = false;
      this.getMedia();
      this.clearCanvas();
    },
    // 拍照
    takePhoto() {
      if (this.loading) true;
      this.loading = true;
      this.clearCanvas();
      let ctx = this.canvas.getContext('2d');
      ctx.clearRect(0, 0, this.height, this.width)
      ctx.translate(0, this.canvas.height);
      ctx.rotate(270 * Math.PI / 180)
      ctx.drawImage(this.video, 0, 0, this.width, this.height);

      this.imgData = this.canvas.toDataURL("image/jpeg");
      this.video.pause();
      this.submitFun();
    },

    // 清空画布
    clearCanvas() {
      this.canvas.getContext("2d");
      this.canvas.height = this.canvas.height;
    },

    // 关闭摄像头
    close() {
      this.video.pause();
      this.MediaStreamTrack && this.MediaStreamTrack.stop();

      if (this.picView) {
        this.picView.destroy();
      }
      this.$store.commit("setCameraInfo2", {
        showWin: false,
        info: {}
      });
    },

    isToday(datestr) {
      if (!datestr) return false;
      return (
        this.common.parseTime(new Date(), "{y}-{m}-{d}") ==
        this.common.parseTime(new Date(datestr), "{y}-{m}-{d}")
      );
    },

    clickPic(e) {
      if (this.picView) this.picView.destroy();
      this.picView = new viewer(this.$refs.images, { zIndex: 10000 });
    },

    // 保存病历扫描图片
    addMedicalRecordScan(url) {
      let obj = {
        patientCode: this.cameraInfo2.info.patientCode,
        registerCode: this.cameraInfo2.info.registerCode,
        registerDatetime: this.cameraInfo2.info.registerDateTime,
        medicalRecordScanDetailDTOList: [
          {
            picUrl: url,
            picType: this.typeId // (0.病历扫描 1.手相)
          }
        ],
      };

      this.api2("addMedicalRecordScan", obj).then(res => {
        this.loading = false;
        if (this.common.isError(res)) return;
        this.$Message.success(res.result.msg);

        res.result.details[0]["picUrl"] = this.imgData;
        res.result.details[0]["crDt"] = this.common.parseTime(
          new Date(),
          "{y}-{m}-{d} {h}:{i}"
        );
        this.oldPics.push(res.result.details[0]);

        this.video.play();

        //更新数据
        this.$store.commit("setPicLabeling", {
          id: obj.registerCode,
          value: 1
        });
      });
    },
    delPic(item, index, isOld) {
      this.$Modal.confirm({
        title: "提示",
        content: "确定要删除图片吗？",
        onOk: () => {
          let list = isOld ? this.oldPics : this.newPics;
          if (isOld) {
            this.api2("deleteMedicalRecordScan", { aidId: item.aidId }).then(res => {
              list.splice(index, 1);
              if (!list.length) {
                //更新子页里的数据
                this.$store.commit("setPicLabeling", {
                  id: this.cameraInfo2.info.registerCode,
                  value: 0
                });
              }
            });
          } else {
            list.splice(index, 1);
          }
        },
        onCancel: () => { }
      });
    },

    // 拍照结果
    submitFun() {
      // if (this.loading) return;
      let fileName = this.cameraInfo2.info.patientCode + this.cameraInfo2.info.hospitalCode;
      fileName += this.common.parseTime(new Date(), "{y}{m}{d}{h}{i}{s}.jpg")
      var fileObj = this.common.base64ToFile(this.imgData, fileName); //base64数据转为文
      this.upLoadImage([
        {
          key: "multipartFiles",
          file: fileObj
        }
      ]);
    },

    // 上传图片
    upLoadImage(obj) {
      // typeId值，0病历图片 1手相图片 2面相图片 3穴位图片 4伙伴图片 5药品图片 6其他图片
      obj.push({
        key: "typeId",
        file: 0
      });
      this.loading = true;
      this.api2("fileUploadNew", obj).then(res => {
        // 保存病历扫描图片
        this.addMedicalRecordScan(res.result);
      }).finally(() => {
        this.loading = false;
      })

    },

    getData() {
      let obj = {
        patientCode: this.cameraInfo2.info.patientCode,
        registerCode: this.cameraInfo2.info.registerCode,
        picType: this.typeId
      };
      this.api2("queryMedicalRecordScan", obj).then(res => {
        this.oldPics = res.result;
      });
    }
  },
  mounted() {
    this.video = this.$refs.video2;
    this.canvas = this.$refs.canvas

    this.showCamera = this.cameraInfo2.showWin;
    $(document).keydown(event => {
      if (this.showCamera) {
        if (event.keyCode == 120) {
          this.takePhoto();
        } else if (event.keyCode == 27) {
          this.close();
        }
      }
    });
  },
  beforeDestroy() {
    this.close();
  }
};
</script>

<style>
.camera .patientInfo {
  position: absolute;
  top: 14px;
  left: 110px;
  padding: 0;
  background: none;
}

.camera .patientInfo span {
  padding-left: 10px;
  color: #853a2f;
}

.camera .patientInfo span.name {
  font-weight: bold;
  font-size: 15px;
}

.camera2 .ivu-modal-body {
  display: flex;
}

.camera2 #CameraCtl2 {
  background: #c7edcc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera #CameraCtl2 img {
  height: 450px;
  width: 600px;
}

.camera2 #CameraCtl2 img {
  /* height: 450px;
  width: 600px; */
  transform: rotate(-90deg);
}

.boot_g {
  text-align: center;
  padding-top: 15px;
}

.boot_g .ivu-btn {
  padding: 3px 40px;
  height: auto;
  font-size: 14px;
}

.camera_status {
  position: absolute;
  background: #ffffff;
  top: 1px;
  left: 1px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera_status .camera_status_content {
  width: 320px;
  line-height: 40px;
  font-size: 15px;
}

.camera_status .c_s_c_title {
  font-size: 18px;
  position: relative;
}

.camera_status .c_s_c_title .ivu-icon {
  position: absolute;
  color: #f00;
  left: -30px;
  top: 8px;
  font-size: 24px;
}

.camera_status .ivu-btn-primary {
  border: none;
  font-size: 12px;
}

.camera .u_pics {
  padding-top: 15px;
}

.camera .u_pics .u_pic {
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px solid #cccccc;
  padding: 3px;
  position: relative;
  margin: 0 10px 10px 0;
}

.camera .u_pics .u_pic .date {
  display: none;
}

.camera2 .u_pics .u_pic .date {
  display: block;
}

.camera .u_pics .u_pic img {
  width: 100%;
  height: 100%;
}

.camera .u_pics .u_pic .del_btn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  color: #f00;
  cursor: pointer;
  opacity: 0.7;
}

.camera .u_pics .u_pic .del_btn:hover {
  opacity: 1;
}

.camera .camera_k {
  position: relative;
}

.camera .camera_k .captureImage_btn .ivu-tooltip,
.camera .camera_k .captureImage_btn .ivu-tooltip-rel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera .camera_k .captureImage_btn {
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.7;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  bottom: 20px;
  margin-left: -40px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.camera .camera_k .captureImage_btn:hover {
  opacity: 1;
}

.camera .camera_k .captureImage_btn .ivu-icon {
  font-size: 40px;
  color: #ffffff;
}

.camera .down_btn {
  float: left;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.camera .timeout_tip {
  flex-direction: column;
}

.camera .timeout_tip button {
  margin-top: 20px;
}

.camera .u_dropdown {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 11;
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3);
  border-right-width: 0;
  border-radius: 15px 0 0 15px;
}

.camera .u_dropdown .ivu-icon-md-more {
  font-size: 20px;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding-left: 5px;
  box-sizing: border-box;
  line-height: 30px;
  text-align: center;
  color: #000;
}

.camera .modal_close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.camera2 .u_pics {
  padding: 0 0 0 15px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.camera2.emptyPic .u_pics {
  display: none;
}

.camera2 .u_pics .u_pic_k {
  height: 100%;
  overflow-y: auto;
}

.camera2 .u_pics .pic_title {
  text-align: center;
  width: 110px;
  background: #eccfad;
  line-height: 24px;
  font-size: 12px;
  margin-bottom: 10px;
}

.camera2 .u_pics .u_pic {
  width: 110px;
  height: 130px;
  text-align: center;
}

.camera .u_pics .u_pic .img_k {
  width: 100%;
  height: 100%;
}

.camera2 .u_pics .u_pic .img_k {
  width: 102px;
  height: 102px;
}

.camera2 .u_pics .u_pic .date {
  font-size: 10px;
  font-style: inherit;
}

.camera .picture-canvas {
  position: absolute;
  visibility: hidden;
  width: 10px;
  height: 10px;
}
</style>
