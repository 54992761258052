<!--患者档案弹出组件-->
<template>
  <div class="u_archives">
    <div class="u_a_btn" @click.stop="show">
      <slot>
        <Button v-if="type == 'btn'"  type="default">档案</Button>
        <span v-else class="grda">
          <Icon type="md-calendar" /> 个人档案
        </span>
      </slot>
    </div>

    <Modal fullscreen class="u_archives_modal" v-model="modal1" :title="info.patientName + '的档案'" footer-hide>
      <udetails v-if="modal1" :patientCode="info.patientCode"></udetails>
    </Modal>
  </div>
</template>

<script>
// import udetails from "@/com/details/details.vue";
import udetails from "@/com/details2/index.vue";
export default {
  props: {
    info: {
      type: Object,
      default: ()=>{
        return {}
      } 
    },
    type: {
      // 类型  tip  btn
      type: String,
      default: "tip"
    }
  },
  components: {
    udetails
  },
  data() {
    return {
      modal1: false
    };
  },
  methods: {
    show() {
      this.modal1 = true;
    }
  }
};
</script>

<style scoped lang="scss">
.u_archives {
  display: inline;
  .u_a_btn{
    display: inline-block;
    cursor: pointer;
  }
}

.u_archives i.iconfont {
  cursor: pointer;
}

.u_archives .grda {
  font-size: 12px;
  cursor: pointer;
}
.u_archives_modal {

  .ivu-modal-body{
    right: 20px;
    padding-right: 20px;
  }
}
</style>