<template>
  <div class="cause-feedback">
    <Button type="primary" size="small" @click="open">补充病因反馈</Button>
    <div class="popup" v-if="show">
      <div class="hander">
        <div class="title">
          症状：<span>{{ info.dsName }}</span>
        </div>
        <Icon type="md-close" @click="close" />
      </div>
      <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        label-position="top"
      >
        <FormItem label="请输入对上面症状需补充反馈的新病因" prop="reason">
          <Input
            v-model="formValidate.reason"
            placeholder="请输入"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
          />
        </FormItem>
        <div class="footer">
          <Button type="primary" @click="handleSubmit('formValidate')"
            >提交</Button
          >
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    info() {
      this.formValidate.reason = "";
    }
  },
  data() {
    return {
      show: false,
      formValidate: {
        reason: "",
      },
      ruleValidate: {
        reason: [
          {
            required: true,
            message: "请输入对上面症状需补充反馈的新病因",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.postData();
        } else {
          this.$Message.error("请将信息填写完整!");
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    postData() {
      this.api2("supplyReason", {
        dsId: this.info.dsId,
        dsName: this.info.dsName,
        reason: this.formValidate.reason,
      }).then((res) => {
        this.$Message.success(res.result);
        this.formValidate.reason = "";
        this.close();
      });
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cause-feedback {
  position: relative;
  .popup {
    position: absolute;
    z-index: 10;
    right: -10px;
    top: 40px;
    width: 265px;
    padding: 15px;
    background: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    :deep {
      .ivu-form-item-error-tip {
        font-size: 12px;
      }
      .ivu-form .ivu-form-item-label {
        font-size: 13px;
      }
    }
    .title {
      span {
        font-weight: bold;
      }
    }
  }
  .footer {
    text-align: center;
  }
  .hander {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ivu-icon {
      font-size: 20px;
      cursor: pointer;
    }
  }
}
</style>