<!-- 登录页 -->
<template>
  <layer pagename="login" pageClass="loginPage">
    <div class="u_con login_yk">
      <div class="login">
        <div class="login_logo"></div>
        <!-- <img class="xys" src="@/assets/images/npc.gif" alt=""> -->
        <!-- <img class="xys" src="@/assets/images/how2.png" alt=""> -->

        <Form ref="userInfo" :model="userInfo" :label-width="0" :rules="rules1">
          <div class="text">
            <div class="chn-text">用户登录</div>
            <div class="en-text">user&nbsp;login</div>
          </div>

          <!--打乱浏览器默认规则的元素-->
          <div class="outside_input">
            <input type="text" tabindex="-1" />
            <input type="password" tabindex="-1" />
          </div>

          <FormItem prop="fAccount">
            <Input autofocus @on-enter="nextInput($event)" v-model="userInfo.fAccount" placeholder="用户名">
            <Icon type="ios-person-outline" slot="prepend"></Icon>
            </Input>
          </FormItem>
          <FormItem prop="fPassWord">
            <Input @on-enter="nextInput($event)" v-model="userInfo.fPassWord" type="password" placeholder="密码">
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
            </Input>
          </FormItem>
          <!-- <FormItem prop="frepPassWord">
            <Input @on-enter="nextInput($event)" v-model="userInfo.frepPassWord" type="password" placeholder="确认密码">
            <Icon type="ios-lock-outline" slot="prepend"></Icon>
            </Input>
          </FormItem> -->
          <FormItem prop="verifyCode">
            <div class="code">
              <Input @on-enter="submitForm('userInfo')" v-model="userInfo.verifyCode" placeholder="验证码">
              <Icon type="ios-lock-outline" slot="prepend"></Icon>
              </Input>
              <img @click="getVerifyCode" :src="codeUrl" alt="点击刷新" />
            </div>
          </FormItem>
          <div class="tip">人人病有所医</div>
          <Button type="info" @click="submitForm('userInfo')">登录</Button>
        </Form>
      </div>
    </div>
  </layer>
</template>

<script>
import { mapGetters } from "vuex";
import setKeyjs from "@/com/setKey.js";
export default {
  data() {
    return {
      codeUrl: "",
      uuid: "",
      userInfo: {
        fAccount: "",
        fPassWord: "",
        frepPassWord: "",
        verifyCode: "",
      },
      rules1: {
        fAccount: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        fPassWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
        frepPassWord: [{ required: true, message: "请输入确认密码", trigger: "blur" }, { validator: this.repPasswordValidator }],
        verifyCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["onLogin"]),
  },
  methods: {
    repPasswordValidator(rule, value, callback) {
      if (value != this.userInfo.fPassWord) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    },
    nextInput(e) {
      this.common.nextInput(e);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {

          this.$store.commit('clearStorage')
          if (config.isEnableDogLock) {
            let ukey = setKeyjs.getInstance();
            let res = await ukey.mylogin_onclick(this.userInfo.fAccount);
          }

          localStorage.setItem("isFromLoginPage", 1);
          this.$store.dispatch("getUserLogin", {
              userId: this.userInfo.fAccount,
              passWord: this.userInfo.fPassWord,
              verifyCode: this.userInfo.verifyCode, //验证码
              verifyCodeImgId: this.uuid, // 	验证码图片Id
            }).then((res) => {
            }).catch((err) => {
                this.getVerifyCode();
            });
        } else {
          this.$Message.error("信息输入不正确！");
        }
      });
    },
    getVerifyCode() {
      this.api2("getVerifyCode", {
        sysCode: "ai",
      }).then((res) => {
        this.codeUrl = "data:image/jpeg;base64," + res.result.img;
        this.uuid = res.result.uuid;
      });
    },
  },
  mounted() {
    this.getVerifyCode();
  },
};
</script>
<style >
.outside_input {
  /* position: absolute;
    left: -10000px; */
  height: 0;
  overflow: hidden;
}

.login .code {
  display: flex;
}

.login .code .ivu-input-group {
  width: auto;
  flex: 1;
}

.login .code img {
  width: 100px;
  height: 44px;
  border-radius: 5px;
  margin-left: 10px;
  flex: 0;
}

/* .login .xys {
  position: absolute;
  left: -50px;
  top: -50px;
  width: 90px;
  height: 90px;
  padding: 5px;
  background: #fff;
  border-radius: 100%;
} */
/* .login .xys {
  position: absolute;
  left: -70px;
  top: -80px;
  width: 100px;
  height: 100px;
  padding: 5px;
} */

/* .login .xys {
  position: absolute;
  left: 50%;
  top: -60px;
  margin-left: -55px;
  z-index: 10;
  width: 100px;
  height: 100px;
  padding: 5px;
  border-radius: 100%;
  border: 3px solid #ffffff33;
  background: #e5ad91;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, .1);
} */

/* .login .ivu-form {
  position: relative;
  z-index: 3;
  background: #e5ad91 !important;
  padding-top: 50px !important;
} */
</style>

