<template>
  <div class="u_s_g u_date">
    <span class="label">{{ dateLabel }}</span>
    <DatePicker
      :size="size"
      :value="date"
      @on-change="change"
      type="daterange"
      format="yyyy-MM-dd"
      show-week-numbers
      placement="bottom-end"
      placeholder="请选日期"
    ></DatePicker>
    <ul class="date_g">
      <Button @click="setDate(1)">今天</Button>
      <Button @click="setDate(2)">昨天</Button>
      <Button @click="setDate(3)">前一天</Button>
      <Button @click="setDate(4)">本月</Button>
      <Button @click="setDate(5)">上一月</Button>
    </ul>
  </div>
</template>
<script>
import common from "@/common.js";
export default {
  props: {
    dateLabel: {
      type: String,
      default: "日期"
    },
    value: {
      type: [String, Number, Array],
      default: ""
    },
    size: {
      type: String,
      default: "default"
    }
  },
  data() {
    return {
      date: this.value
    };
  },
  watch: {
    value(val) {
      this.date = val;
    }
  },
  methods: {
    change(value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
    setDate(index) {
      switch (index) {
        case 1:
          {
            //今天
            var jt = common.timestampToTime();
            this.date = [jt, jt];
          }
          break;
        case 2:
          {
            //昨天
            var zt = common.getDate(-1);
            this.date = [zt, zt];
          }
          break;
        case 3:
          {
            //前一天
            var qt = common.getDate(-1, this.date[0]);
            this.date = [qt, qt];
          }
          break;
        case 4:
          {
            //本月
            var qt = common.timestampToTime();
            var st = qt.replace(/\d+$/, 1);
            this.date = [st, qt];
          }
          break;
        case 5:
          {
            //上一月
            var qt = common.timestampToTime(this.date[0]);
            var dqr = qt.match(/\d+$/);
            dqr = dqr[0] * 1;
            var end = common.getDate(-dqr, qt);
            var star = end.replace(/\d+$/, 1);
            this.date = [star, end];
          }
          break;
      }
      this.date[0] = this.common.parseTime(this.date[0], "{y}-{m}-{d}");
      this.date[1] = this.common.parseTime(this.date[1], "{y}-{m}-{d}");
      this.change(this.date);
    }
  },
  create() {},
  mounted() {}
};
</script>
<style scoped>
.u_date {
  position: relative;
  width: 255px;
  flex-shrink: 0;
}

.u_date .date_g {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fcfcf4;
  z-index: 10;
  padding: 10px;
  border: 1px solid #ddd;
  border-top-width: 0;
  display: none;
}

.u_date:hover .date_g {
  display: flex;
}

.u_date .date_g button {
  width: 100%;
  padding: 5px;
  margin-left: -1px;
  position: relative;
  z-index: 1;
}

.u_date .date_g button:hover {
  z-index: 2;
}
</style>