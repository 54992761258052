var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"etiology-select",class:{ animate__flash: _vm.animateRun }},[_c('div',{staticClass:"e-hender"},[_vm._m(0),(_vm.symptomInfo.dsName && _vm.showCauseFeedback)?_c('causeFeedback',{attrs:{"info":_vm.symptomInfo}}):_vm._e()],1),(_vm.show)?_c('div',{staticClass:"e-content"},[_c('div',{staticClass:"e-c-hender"},[_c('div',{staticClass:"e-c-hender-title"},[_vm._v(" 症状: "),_c('span',[_vm._v(_vm._s(_vm.symptomInfo.dsName))])])]),(_vm.etiologyList.length)?_c('div',{staticClass:"e-list"},_vm._l((_vm.etiologyList),function(item,index){return _c('div',{key:index,ref:'etiology_item_' + index,refInFor:true,staticClass:"e-list-item"},[_c('Checkbox',{attrs:{"label":"twitter"},on:{"on-change":function($event){return _vm.change(item, index)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.etiologyName))])]),_vm._l((item.children),function(i_item,i_index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.value),expression:"item.value"}],key:i_index,staticClass:"input-wrap"},[_c('div',{staticClass:"icon"},[(i_index == item.children.length - 1)?_c('Icon',{attrs:{"type":"md-add"},on:{"click":function($event){return _vm.add(item.children)}}}):_vm._e()],1),_c('xAutoComplete',{ref:'etiology_input_' + index + '_' + i_index,refInFor:true,attrs:{"maxlength":"50","data":_vm.getList(item.etiologyId),"filter-method":_vm.handleSearch1,"placeholder":"请输入底层原因"},on:{"on-change":function($event){return _vm.changeInput(item)},"on-enter":function($event){_vm.enter(
                item.children,
                i_index,
                'etiology_input_' + index + '_' + (i_index + 1)
              )},"on-select":function($event){_vm.enter(
                item.children,
                i_index,
                'etiology_input_' + index + '_' + (i_index + 1)
              )}},model:{value:(i_item.label),callback:function ($$v) {_vm.$set(i_item, "label", $$v)},expression:"i_item.label"}}),_c('div',{staticClass:"icon remove-btn"},[_c('Icon',{attrs:{"type":"ios-close"},on:{"click":function($event){return _vm.removeItem(item, i_index)}}})],1)],1)})],2)}),0):_c('empty',[_vm._v("暂无数据")])],1):_c('div',{staticClass:"e-content"},[_vm._m(1),_c('empty',[_vm._v("暂无数据")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('i',{staticClass:"iconfont icon-tianjiawenjianjia1"}),_c('span',[_vm._v("选病因")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"e-c-hender"},[_c('div',{staticClass:"e-c-hender-title"},[_vm._v("请选择症状")])])
}]

export { render, staticRenderFns }