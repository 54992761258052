<template>
	<div class="details3">
		<div class="left_basic_info">
			<div class="details3_title">
				基本信息
			</div>
			<div class="details3_body">
				<div class="patientInfo">
					<span class="name">{{ patientInfo.patientName }}</span>
					<span>
						<Icon v-if="patientInfo.genderName == '男'" type="md-male" />
						<Icon v-else type="md-female" />
					</span>
					<span class="gender">{{ patientInfo.genderName }}</span>
					<span class="age">
						<template v-if="patientInfo.patientAge > 1">{{ patientInfo.patientAge }}岁</template>
						<template v-else>
							<template v-if="patientInfo.birthMonth">{{ patientInfo.birthMonth + "个月" }}</template>
						</template>
					</span>
					<span class="phone">{{ patientInfo.cellphoneNo }}</span>
				</div>
				<div class="item">
					<div v-uEdition="'YZ-07'" class="patientinfo-other">标签:
						<span>{{ labelStr }}</span>
						<Icon type="md-create" @click="openLabel" />
						<patient-label hideBtn ref="patientLabel" :info="patientInfo" @anan="()=>isAnAn=true" />
					</div>
				</div>
				<div class="item" v-for="(item, index) in list" :key="index">
					<span class="label">{{ item.label }}</span>
					<span class="value">{{ patientInfo[item.key] || '/' }}</span>
				</div>
				<el-amap v-if="showMap" vid="amapDemo" :center="mapCenter" :zoom="15" class="amap-demo">
					<el-amap-marker v-for="(marker, index) in markers" :key="index" :position="marker"></el-amap-marker>
				</el-amap>
			</div>
		</div>
		<div class="right">
			<div class="details3_title">
				<div class="tabs">
					<span @click="clickTabs(item)" v-for="item in tabs" :key="item.value" class="tabs_item"
						:class="{ active: item.value == tabsValue }">{{ item.label
						}}</span>
				</div>
			</div>
			<div class="details3_body">
				<medicalRecord3 v-if="tabsValue == 1" :patientCode="patientCode" @updateInfo="updateInfo" />
				<handPic v-if="tabsValue == 2" :patientCode="patientCode" />
				<div v-if="tabsValue == 3 " class="chart-tab">
					<sungoChart :sunGoUserInfo="patientInfo" useType="patientInfo"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import handPic from '@/com/handPic'
import medicalRecord3 from './com/medicalRecord3.vue'
import sungoChart from '@/com/sungoChart/chart.vue'
import PatientLabel from '../patientInfo/com/patientLabel.vue';
export default {
	props: {
		patientCode: {
			type: String,
			default: ""
		},
	},
	components: {
		medicalRecord3,
		handPic,
		sungoChart,
		PatientLabel
	},
	computed: {
		labelStr() {
			let arr = [];
			if (this.patientInfo.courseCrowdName) arr.push("第三类人群")
			if (this.patientInfo.isCourseManage) arr.push("病程管理")
			// if (this.isAnAn) arr.push("安安适用人群")
			return arr.join(" / ")
		}
	},
	data() {
		return {
			isAnAn: false,
			showMap: false,
			mapCenter: [0, 0],
			markers: [],
			tabsValue: 1,
			tabs: [
				{
					label: '就诊记录',
					value: 1,
					active: true,
				},
				{
					label: '手相解读',
					value: 2,
					active: false,
				},
				{
					label: '手环数据',
					value: 3,
					active: false,
				},
				// {
				// 	label: '病案分析',
				// 	value: 3,
				// 	active: false,
				// },
			],
			list: [
				{
					label: '来源渠道',
					key: 'patientSourceName',
				},
				{
					label: '出生年月',
					key: "patientBirthday"
				},
				{
					label: '婚否',
					key: "maritalName"
				},
				{
					label: '职业',
					key: '-',
				},
				{
					label: '民族',
					key: 'nation',
				},
				{
					label: '喜好、生活状态',
					key: 'hobbyInfo',
				},
				{
					label: '节育史',
					key: 'birthControlHistory',
				},
				{
					label: '既往史',
					key: 'medicalHistory',
				},
				{
					label: '现病史',
					key: 'medicalHistory',
				},
				{
					label: '生育史',
					key: 'birthHistory',
				},
				{
					label: '过敏史',
					key: 'allergyHistory',
				},
				{
					label: '家族史',
					key: 'familyHistory',
				},
				{
					label: '使用其他药物情况',
					key: 'useDrugRecord',
				},
				{
					label: '备注',
					key: 'otherInfo',
				},
				{
					label: '地址',
					key: 'address',
				},
			],
			patientInfo: {},
		}
	},
	methods: {
		openLabel() {
			this.$refs.patientLabel.showFun();
		},
		clickTabs(item) {
			this.tabsValue = item.value;
		},
		setAmap() {
			let x = this.patientInfo.locationX;
			let y = this.patientInfo.locationY;
			if (x && y) {
				this.mapCenter = [x, y];
				this.markers = [this.mapCenter];
				this.showMap = true;
			} else if (this.patientInfo.address) {
				this.postAddress();
			}
		},
		postAddress() {
			let city = this.patientInfo.cityName || "上饶市";
			let arrStr = this.patientInfo.address;
			var geocoder = new AMap.Geocoder({ city });

			geocoder.getLocation(arrStr, (status, result) => {
				if (status === "complete" && result.info === "OK") {
					let loc = result.geocodes[0].location;
					let lnglat = [loc.lng, loc.lat];
					this.mapCenter = lnglat;
					this.markers = [this.mapCenter];
					this.showMap = true;
				}
			});
		},
		init() {
			this.api2("getPatientBaseInfo", {
				patientCode: this.patientCode, //	患者code
			}).then(res => {
				this.patientInfo = res.result;
				this.setAmap();
				// 暂时关闭
				// this.getPatientAnanAuth();
			})
		},
		updateInfo(obj){
			obj.diagnosticId2 = obj.diseaseType2;
			obj.diagnosticName2 = obj.diseaseName;
			Object.assign(this.patientInfo, obj);
		},
		getPatientAnanAuth() {
			let obj = {
				patientCode: this.patientInfo.patientCode
			};
			this.api2('getPatientAnanAuth', obj).then(res => {
				if(res.result && res.result.bindStatus == "1"){
				this.isAnAn = true;
				}else{
				this.isAnAn = false;
				}
			}).finally(() => {
			})
		},
	},
	mounted() {
		this.init();
	},

}
</script>

<style lang="scss" scoped>
@import url("./style.css");
.chart-tab{
	height: 100%;
	padding: 20px;
}
.patientinfo-other{
	.ivu-icon{
		cursor: pointer;
	}
}
</style>