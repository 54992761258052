<!-- 病人信息 -->
<template>
  <div class="patientInfo1">
    <div v-if="pageType" class="assistant_menu">
      <div class="as_left">
        <div v-uEdition="'YZ-02'" class="as_menu_item" :class="{ active: menuName == '2' }" @click="dropMenu('2')">
          <Badge dot :count="hotMenuList[1].value">五运六气</Badge>
        </div>
        <div v-uEdition="'YZ-04'" class="as_menu_item" :class="{ active: menuName == '3' }" @click="dropMenu('3')">
          <Badge dot :count="hotMenuList[2].value">穴位按摩</Badge>
        </div>
        <div v-uEdition="'YZ-05'" class="as_menu_item" :class="{ active: menuName == '4' }" @click="dropMenu('4')">西医报告
        </div>
        <div v-uEdition="'YZ-06'" class="as_menu_item" :class="{ active: menuName == '1' }" @click="dropMenu('1')">
          <Badge dot :count="hotMenuList[0].value">话术方向</Badge>
        </div>
      </div>
    </div>
    <div class="p_mk b_1">
      <div class="info">
        <uArchives :info="info">
          <span class="name">{{ info.patientName }}</span>
        </uArchives>
        <span class="bg1" v-if="info.occupationName">
          <Tooltip :content="info.occupationCall" placement="top-start">
            {{ info.occupationName }}
          </Tooltip>
        </span>
        <span class="bg1 gender">
          <Icon v-if="info.sex == '男'" type="md-male" />
          <Icon v-else type="md-female" />
          {{ info.sex }}
        </span>
        <span class="bg1 age">
          <template v-if="info.patientAge > 1">{{ info.patientAge }}岁</template>
          <template v-else>
            <template v-if="info.birthMonth">{{ info.birthMonth + "个月" }}</template>
          </template>
        </span>
        <span class="bg1 maritalStatus">{{ info.maritalStatus }}</span>
        <span class="bg1 phone">{{ info.cellphoneNo }}</span>
        <uArchives :info="info"></uArchives>
        <!-- <patient-label hideBtn ref="patientLabel" :info="info" v-uEdition="'YZ-07'" /> -->
      </div>
      <div v-if="pageType" class="grda">
        <div class="cameraBtns">
          <!-- 暂时隐藏 -->
          <Button icon="md-watch" class="ubtn_icon" shape="circle" size="small" @click="showSungoData">手环数据</Button>
          <Button v-uEdition="'YZ-08'" @click="showCameraFun(0)" class="ubtn_icon" shape="circle" size="small"
            icon="md-camera">病历扫描</Button>
          <span v-uEdition="'YZ-01'" :class="{ handPic_btng: info.picUrl }">
            <Button @click="showCameraFun(1)" class="btn1 ubtn_icon" shape="circle" size="small"
              icon="md-hand">手相拍照</Button>
            <Button v-if="info.picUrl" @click="showCameraFun(1)" class="btn2 ubtn_icon havePic" shape="circle"
              size="small" icon="md-checkmark" type="primary">已拍手相</Button>
          </span>
          <Button v-if="showFeedbackSheet" @click="medicalRecordOpenFun" class="ubtn_icon" shape="circle" size="small"
            icon="md-print">用户反馈单</Button>
        </div>
        <medicalRecord2 v-model="medicalRecordOpen" :info="info" />
      </div>
    </div>
    <div v-uEdition="'YZ-07'" class="patientinfo-other">
      标签:
      <span>{{ labelStr }}</span>
      <Icon type="md-create" @click="openLabel" />
      <patient-label hideBtn ref="patientLabel" :info="info" @anan="()=>isAnAn=true" parent-page='patientRecord'/>
    </div>

    <div v-show="menuName" class="pI_popup">
      <Icon class="close" @click="menuName = ''; useClock = true" type="md-close-circle" />
      <div class="pi_body">
        <perfect-scrollbar ref="scrollbar">
          <speechTechnique v-if="menuName == 1" :info="info" />
          <luck v-if="menuName == 2" :info="info" />
          <acupoint v-if="menuName == 3" />
          <report :info="info" v-if="menuName == 4" @close=" menuName = ''" />
        </perfect-scrollbar>
      </div>
    </div>
    <sungoChart v-if="showSungo" :sunGoUserInfo="sunGoUserInfo" @closeSungo="closeSungoHandle"/>
  </div>
</template>

<script>
import acupoint from "./com/acupoint";
import report from "./com/report";
import luck from "./com/luck";
import speechTechnique from "./com/speechTechnique";
import sungoChart from '@/com/sungoChart/index.vue'
import { mapGetters } from "vuex";
import PatientLabel from './com/patientLabel.vue';
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      }
    },
    hideMenu1: {
      type: Boolean,
      default: false
    },
    pageType: {
      //页面类型  true:前端医生 false:后端医生
      type: Boolean,
      default: false
    },
    isRightPopup: {
      // 智能辅助弹框在右边
      type: Boolean,
      default: false
    },
    isAutoPopup: {
      // 是否自动弹出五运六气
      type: Boolean,
      default: false
    }
  },
  watch: {
    info: {
      handler: function (value, oldValue) {
        this.menuName = "";
        this.showAddTag = false;
        this.useClock = false;
        if (value.registerCode != oldValue.registerCode) {
          this.getAcupointData();
        }
      },
      deep: true
    },
    diseaseEmotion(value) {
      this.setHotMenuList();
    },
    acupoint(newValue, oldValue) {
      let kg = true;
      if (!newValue.diagnosticId) kg = false;
      if (!newValue.symptomsIds && !oldValue.symptomsIds) kg = false;

      if (kg && this.$route.name == "condition") this.getAuxiliaryToolData2();
    }
  },
  components: {
    acupoint,
    report,
    luck,
    speechTechnique,
    PatientLabel,
    sungoChart
  },
  computed: {
    ...mapGetters([
      "diseaseEmotion",
      "acupoint",
      "introStart",
      "userSystemConfig"
    ]),
    showFeedbackSheet() {
      return (
        this.userSystemConfig.userFeedback &&
        location.pathname == "/pages/prescription/index.html"
      );
    },
    labelStr() {
      let arr = [];
      if (this.info.courseCrowdName) arr.push("第三类人群")
      if (this.info.isCourseManage) arr.push("病程管理")
      // if (this.isAnAn) arr.push("安安适用人群")
      return arr.join(" / ")
    }
  },
  data() {
    return {
      isAnAn: false,
      medicalRecordOpen: false,
      menuName: "",
      showPopup: false,
      useClock: false, //用户是否有关过弹框

      hotMenuList: [
        { label: "话术方向", value: 0 },
        { label: "五运六气", value: 0 },
        { label: "穴位按摩", value: 0 }
      ],
      reportShow: false,
      acupointShow: false,
      speechTechniqueShow: false,
      luckShow: false,
      showAddTag: false,
      djs: null,
      showSungo: false,
      sunGoUserInfo: {}
    };
  },
  methods: {
    getPatientAnanAuth() {
        let obj = {
            patientCode: this.info.patientCode
        };
        this.api2('getPatientAnanAuth', obj).then(res => {
            if(res.result && res.result.bindStatus == "1"){
              this.isAnAn = true;
            }else{
              this.isAnAn = false;
            }
        }).finally(() => {
        })
    },
    openLabel() {
      this.$refs.patientLabel.showFun();
    },
    medicalRecordOpenFun(index) {
      //弹窗打开
      this.medicalRecordOpen = true;
    },

    getAuxiliaryToolData2() {
      clearTimeout(this.djs);
      this.djs = setTimeout(() => {
        this.getAuxiliaryToolData(1);
      }, 500);
    },
    setHotMenuList() {
      if (this.diseaseEmotion.list.length) {
        this.hotMenuList[0].value = 1;
      }
    },
    getAcupointData() {
      this.resetAcupoint();
      this.getAuxiliaryToolData(0);
      // 暂时关闭
      // this.getPatientAnanAuth();
    },

    dropMenu(name) {
      this.menuName = this.menuName == name ? "" : name;
    },

    showCameraFun(typeId) {
      let fun = typeId ? "setCameraInfo3" : "setCameraInfo2";
      this.$store.commit(fun, {
        info: this.info,
        typeId,
        showWin: true
      });
    },
    getAuxiliaryToolData(typeId) {
      let obj = {
        diagnosticId: this.acupoint.diagnosticId, //	诊断id
        dsIds: this.acupoint.symptomsIds, //	症状集('30154|30156|30148|30514|30119|30304|30150|30430|30290|30234|'),
        patientCode: this.info.patientCode //	患者编码
        // typeId //	0.五运六气 1.话术、穴位按摩
      };
      return this.api2("getAuxiliaryToolData", obj).then(res => {
        // this.$store.commit("sendLog", {
        //   remarks: "五运六气、话术、穴位按摩是否有数据",
        //   apiName: "getAuxiliaryToolData",
        //   outParams: res,
        //   inParams: obj
        // });

        let resdata = res.result;
        //话术数据
        if (resdata.emotion && resdata.emotion.length) {
          this.hotMenuList[0].value = 1;
          this.$store.commit("diseaseEmotion", {
            list: resdata.emotion,
            diagnosticName: this.acupoint.diagnosticName
          });
        } else {
          this.hotMenuList[0].value = 0;
          this.$store.commit("diseaseEmotion", {
            list: [],
            diagnosticName: ""
          });
        }
        //穴位数据
        if (resdata.acquaint && resdata.acquaint.length) {
          this.hotMenuList[2].value = 1;
          this.$store.commit("acupointList", resdata.acquaint);
        } else {
          this.hotMenuList[2].value = 0;
          this.$store.commit("acupointList", []);
        }

        //五运六气数据
        if (resdata.flsq && resdata.flsq.length) {
          this.hotMenuList[1].value = 0;
          this.$store.commit("flsq", resdata.flsq[0]);
        } else {
          this.hotMenuList[1].value = 1;
          this.$store.commit("flsq", null);
          if (this.isAutoPopup && !this.useClock && !this.introStart) {
            this.menuName = 2; //是否自动弹出五运六气
          }
        }
      });
    },
    // 重置辅助工具状态
    resetAcupoint() {
      this.hotMenuList.forEach(i => (i.value = 0));
      this.$store.commit("flsq", null);
      this.$store.commit("acupointList", []);
      this.$store.commit("diseaseEmotion", {
        list: [],
        diagnosticName: ""
      });
    },
    init() {
      if (this.pageType) {
        this.getAcupointData();
      }
    },
    //显示手环数据
    showSungoData(){
      console.log('this.info')
      console.log(this.info)
      this.sunGoUserInfo = this.info
      console.log(this.sunGoUserInfo)
      this.showSungo = true
    },
    closeSungoHandle(){
      this.showSungo = false
    }
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.patientInfo1 {
  padding-bottom: 2px;
  background: #ffffff;
}

.patientInfo1.patientInfo1_xh {
  padding: 0;
}

.patientInfo1 .b_2 .ivu-date-picker .ivu-input,
.patientInfo1 .b_2 .ivu-input-wrapper,
.patientInfo1 .b_2 .ivu-date-picker-rel,
.patientInfo1 .b_2 .ivu-date-picker {
  height: 18px;
}

.patientInfo1 .b_2 .ivu-date-picker {
  width: 10px;
}

.patientInfo1 .b_2 .ivu-date-picker.isPeriod {
  width: 80px;
}

.patientInfo1 .b_2 .ivu-date-picker .ivu-input-suffix {
  display: none;
}

.patientInfo1 .b_2 .ivu-date-picker .ivu-input {
  border: none;
  padding: 1px 0px;
}

.patientInfo1 .b_2 .ivu-input-wrapper {
  width: auto;
}

.patientInfo1 .b_2 .xh {
  display: flex;
}

.patientInfo1 .b_2 .xh .label {
  display: flex;
}

.patientInfo1 .ivu-checkbox-group .ivu-checkbox-group-item {
  margin-bottom: 6px;
  color: #333;
}

.patientInfo1 .p_mk {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 16px;
}

.patientInfo1 .p_mk.b_1 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.patientInfo1 .p_mk.b_2 {
  font-size: 12px;
  display: flex;
  align-items: flex-start;
}

.patientInfo1 .p_mk.b_2 .label {
  white-space: nowrap;
}

.patientInfo1 .p_mk.b_2>div {
  display: flex;
  width: 100%;
}

.patientInfo1 .p_mk .info {
  display: flex;
  align-items: center;
}

.patientInfo1 .p_mk .info .u_archives  {
  .grda{
    display: inline-block;
    line-height: 19px;
    padding: 0 5px;
    border-radius: 3px;
    border: 1px solid #ddd;
  }
}

.patientInfo1 .p_mk .info .bg1 {
  background: #eee;
  padding: 0 5px;
}

.patientInfo1 .p_mk .info>span {
  margin-right: 5px;
}

.patientInfo1 .p_mk .name {
  font-size: 20px;
  font-weight: bold;
  padding-right: 10px;
}

.patientInfo1 .edit_btn {
  cursor: pointer;
  padding: 0 5px;
}

.patientInfo1 .u_popup {
  padding: 25px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: absolute;
  margin-top: 5px;
  top: 100%;
  right: 0;
  width: 400px;
  min-height: 200px;
  background: #ffffff;
  z-index: 100;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
}

.patientInfo1 .u_popup::after {
  position: absolute;
  content: " ";
  top: -16px;
  right: 20px;
  border: 8px solid #f00;
  border-color: transparent transparent #ccc transparent;
}

.patientInfo1 .p_tip {
  color: #999;
  font-size: 12px;
}

.patientInfo1 .p_title {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}

.patientInfo1 .p_con {
  margin-bottom: 15px;
}

.patientInfo1 .cameraBtns .ivu-btn {
  margin-left: 10px;
}

.patientInfo1 .ivu-tag-primary,
.patientInfo1 .ivu-tag-primary.ivu-tag-dot .ivu-tag-dot-inner {
  background: #86392f;
}

.patientInfo1 .addTagBtn {
  display: inline-block;
  border: 2px solid #86392f;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  text-align: center;
  color: #86392f;
  cursor: pointer;
  font-size: 12px;
}

.patientInfo1 .ivu-input-wrapper {
  width: 80px;
  margin-right: 5px;
}

.patientInfo1 .more_tag {
  cursor: pointer;
  padding-left: 3px;
}

.patientInfo1 .p_con_tag {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.patientInfo1 .p_btn_g {
  margin-top: 15px;
  text-align: right;
}

.patientInfo1 .p_btn_g .ivu-btn {
  margin-left: 15px;
}

.patientInfo1Modal .ivu-modal-body {
  padding: 15px;
}

.patientInfo1.patientInfo1_xh .ivu-modal-header {
  display: none;
}

.patientInfo1 .yjts {
  margin-left: 20px;
}

.patientInfo1 .assistant_menu {
  position: relative;
  z-index: 21;
  font-size: 13px;
  display: flex;
  width: 100%;
  white-space: nowrap;
  background: #853a2f;
  color: #fff;
  padding: 0 15px 0 2px;
  line-height: 36px;
  border-radius: 5px 5px 0 0;
}

.patientInfo1 .assistant_menu .as_left {
  width: 100%;
  display: flex;
}

.patientInfo1 .assistant_menu .as_left .as_menu_item {
  padding: 0 10px;
  cursor: pointer;
}

.patientInfo1 .assistant_menu .as_left .as_menu_item .ivu-badge-dot {
  top: 2px;
}

.patientInfo1 .assistant_menu .as_left .as_menu_item.active {
  position: relative;
}

.patientInfo1 .assistant_menu .as_left .as_menu_item.active::after {
  content: " ";
  border: 10px solid transparent;
  border-top-color: #853a2f;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -10px;
}

.patientInfo1 .assistant_menu .as_right .icon-double-left {
  font-size: 8px;
}

.patientInfo1 .pI_popup {
  background: #fff;
  padding: 50px 20px 15px 20px;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 20;
}

.patientInfo1 .pI_popup::after {
  content: " ";
  position: absolute;
  border: 10px solid transparent;
  top: 40px;
}

.patientInfo1 .pI_popup.left::after {
  border-left-color: #75645a;
  right: -20px;
}

.patientInfo1 .pI_popup.left {
  right: 100%;
  margin-right: 20px;
}

.patientInfo1 .pI_popup.right {
  left: 100%;
  margin-left: 10px;
}

.patientInfo1 .pI_popup.right::after {
  border-right-color: #75645a;
  left: -20px;
}

.patientInfo1 .pi_title {
  color: #fff;
  padding-bottom: 10px;
}

.patientInfo1 .pi_title span {
  font-size: 17px;
  font-weight: bold;
}

.patientInfo1 .pi_title i.iconfont {
  padding: 0 10px;
}

.patientInfo1 .pI_popup .close {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 55px;
  z-index: 15;
}

.patientInfo1 .pi_body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.patientInfo1 .pi_body .ps {
  height: 100%;
}

.patientInfo1 .bzInfo .title2 {
  font-size: 12px;
  opacity: 0.7;
}

.patientInfo1 .bzInfo .ivu-poptip-body-content {
  height: 150px;
  overflow: hidden;
}

.patientInfo1 .b_2 .bzInfo .ivu-input-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

.patientInfo1 .b_2 .bzInfo .bz_foot {
  text-align: right;
  margin-top: 10px;
}

.patientInfo1 .b_2 .bzInfo .bz_foot .ivu-btn {
  margin-left: 15px;
}

.patientInfo1 .patientinfo-other {
  padding: 0 15px 8px;
}

.patientInfo1 .patientinfo-other .ivu-icon {
  padding: 0 8px;
  cursor: pointer;
}

.patientInfo1 .patient-label-btn {
  border-radius: 20px;
  padding: 0 15px;
  line-height: 20px;
  height: 20px;
  font-size: 13px;
}
</style>