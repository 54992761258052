<template>
    <div class="status-map">
        <!-- <Button class="back" @click="back" type="text" icon="ios-arrow-back">返回</Button> -->
        <commentary ref="commentaryRef"  :ids="[info.diagnosticId2]" :info="info" />
    </div>
</template>

<script>
import commentary from './commentary.vue'
export default
    {
        props: {
            info: {
                type: Object,
                default: () => {
                    return {}
                }
            },
        },
        components: {  commentary },
        methods: {
            back() {
                this.$emit("close");
            },
            submit() {
                return new Promise((resolve, reject) => {
                    this.$refs.commentaryRef.submit().then(resolve).then(reject)
                })
            }
        },
    }
</script>

<style lang="scss" >
.status-map {
    width: 100%;
    height: 100%;

    .ivu-tabs {
        height: 100%;
        display: flex;
        flex-direction: column;

        .ivu-tabs-content-animated {
            height: 100%;
        }

        .ivu-tabs-tabpane {
            overflow-y: auto;
            text-align: center;
        }
    }

    .ivu-tabs-nav-scroll {
        display: flex;
        justify-content: center;
    }

    // .back {
    //     position: absolute;
    //     top: 15px;
    //     left: 0px;
    //     z-index: 2;
    // }
    .back {
       padding: 0; 
    }

    .ivu-tabs-ink-bar {
        background: #853a2f;
    }

    .ivu-tabs-nav .ivu-tabs-tab-active {
        color: #853a2f;
        font-weight: bold;
        font-size: 15px;
    }

    .label {
        text-align: center;
        font-weight: bold;
        padding: 10px 0;
    }
}
</style>