<template>
  <!-- 病历打印 -->
  <div class="medicalRecordPrint" :class="{ medicalRecordPrint_show: !autoPrint }">
    <div ref="print4" class="medicalRecordPrint_body" v-if="recordsData.patientName">
      <div class="mk_title">病历记录</div>
      <div class="hander">
        <div class="u_row">
          <div class="u_item">
            <span class="label">就诊日期:</span>
            <span class="value">{{ recordsData.visitDateTime }}</span>
          </div>
          <div class="u_item">
            <span class="label"></span>
            <span class="value"></span>
          </div>
          <div class="u_item">
            <span class="label checkbox_1">
              <i :class="{ check: cz }"></i>初诊
              <span class="fgx">/</span>
              <i :class="{ check: fz }"></i>复诊
            </span>
          </div>
        </div>
        <div class="u_row">
          <div class="u_item">
            <span class="label">姓名:</span>
            <span class="value">{{ recordsData.patientName }}</span>
          </div>
          <div class="u_item">
            <span class="label">性别:</span>
            <span class="value">{{ recordsData.gender }}</span>
          </div>
          <div class="u_item">
            <span class="label">年龄:</span>
            <span class="value" v-if="recordsData.patientAge">{{ recordsData.patientAge }}岁</span>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="u_row">
          <div class="u_item">
            <span class="label">主诉:</span>
            <span class="value">
              <span>{{ recordsData.chiefComplaintDegree }}</span>
              <span>{{ recordsData.diagnosticName1 }}</span>
              <span>{{ recordsData.chiefComplaintTime }}</span>
              <span v-if="recordsData.chiefComplaintTime">{{
                recordsData.chiefComplaintTimeType
              }}</span>
            </span>
          </div>
          <div class="u_item">
            <span class="label">中医诊断:</span>
            <span class="value">{{ recordsData.diagnosticName2 }}</span>
          </div>
        </div>
        <div class="u_row">
          <div class="u_item">
            <span class="label">既往史:</span>
            <!-- <span class="value">{{ medicalHistory }}</span> -->
            <span class="value">
              <symptomChanges v-for="(item, index) in medicalHistory" :key="index" :symptomInfo="item">{{ item.dsName }}</symptomChanges>
              {{medicalHistory.length ? "": "无"}}
            </span>
          </div>
        </div>
        <div class="u_row">
          <div class="u_item">
            <span class="label">中医四诊:</span>
            <!-- <span class="value" v-html="dsName"></span> -->
            <span class="value">
              <symptomChanges v-for="(item, index) in dsName2" :key="index" :symptomInfo="item">{{ item.dsName }}</symptomChanges>
              <div>{{dsName}}</div>
            </span>
          </div>
        </div>
        <div class="u_row cf">
          <div class="u_item">
            <div class="label">处方:</div>
            <div class="value">
              <drugsFormat :list="drugList" :size="3"></drugsFormat>
              <span v-if="quantity" class="quantity">x{{ quantity }}</span>
            </div>
          </div>
        </div>
        <div class="u_row">
          <div class="u_item">
            <div class="label">医嘱禁忌:</div>
            <span class="value" v-html="r2br()"></span>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="u_row">
          <div class="u_item">
            <span class="label">医师:</span>
            <span class="value">{{ userInfo.doctorName }}</span>
          </div>
          <div class="u_item">
            <span class="label">医师签名(签章):</span>
            <span class="value"></span>
          </div>
        </div>
      </div>
    </div>
    <!-- <Spin v-if="(loading && isSpin)" fix> -->
    <Spin v-if="(indexSpin.show && isSpin)" fix>
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>加载中</div>
    </Spin>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    info: {
      type: Object,
      default: function () {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
    taboo: {
      type: String,
      default: "",
    },
    drugList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 是否显视加载遮罩
    isSpin: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: String | Number,
      default: "",
    },
    // 是否自动打印
    autoPrint: {
      type: Boolean,
      default: true,
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.init();
    },
  },
  computed: {
    ...mapGetters(["userInfo", "bnf", "indexSpin"]),
    fourChineseMedicine() {
      let arr = [this.common.trim(this.recordsData.fourChineseMedicine)];
      if (this.recordsData.chiefComplaint)
        arr.push(this.recordsData.chiefComplaint);
      return arr.join(",");
    },
    medicalHistory() {
      if (!this.recordsData.symptomInfoList) return [];
      return (
        this.recordsData.symptomInfoList.filter((i) => i.isMedicalHistory)
      );
    },
    // dsName() {
    //   if (!this.recordsData.symptomInfoList) return "";
    //   let arr = this.recordsData.symptomInfoList
    //     .filter((i) => !i.isMedicalHistory)
    //     .map((i) => i.dsName);
    //   if (this.recordsData.chiefComplaint) {
    //     arr.push(this.recordsData.chiefComplaint);
    //   }

    //   let menstruationLastTime = "";
    //   if (
    //     this.recordsData.gender == "女" &&
    //     this.recordsData.menstruationLastTime
    //   ) {
    //     menstruationLastTime = `,末次月经${this.recordsData.menstruationLastTime}`;
    //   }
    //   return arr.join(",") + menstruationLastTime;
    // },
    dsName() {
      let menstruationLastTime = "";
      if (
        this.recordsData.gender == "女" &&
        this.recordsData.menstruationLastTime
      ) {
        menstruationLastTime = ` 末次月经${this.recordsData.menstruationLastTime}`;
      }
      return (this.recordsData.chiefComplaint || "") + menstruationLastTime;
    },
    dsName2() {
      if (!this.recordsData.symptomInfoList) return [];
      return this.recordsData.symptomInfoList
        .filter((i) => !i.isMedicalHistory)
    },
    dsIds() {
      return this.recordsData.symptomInfoList.map((i) => i.dsId);
    },
    cz() {
      return this.czIds.some((i) => {
        return this.dsIds.includes(i);
      });
    },
    fz() {
      if (this.cz) return false;
      return this.fzIds.some((i) => {
        return this.dsIds.includes(i);
      });
    },
  },
  data() {
    return {
      // 30417初诊 30216病情好转 30234病情同前 30505前病已愈
      czIds: [30417, 30505], //初诊
      fzIds: [30216, 30234], //复诊
      showWin: true,
      recordsData: { symptomInfoList: [] },
      loading: false,
    };
  },
  methods: {
    r2br() {
      if (this.taboo) {
        return this.taboo;
      } else {
        let taboo =
          (this.recordsData.drugTaboo || "") +
          (this.recordsData.doctorsAdvice || "");
        return taboo ? this.common.tabooFormat(taboo) : "";
      }
    },
    getData() {
      if (!(this.info && this.info.patientCode)) return;
      let obj = {
        patientCode: this.info.patientCode, // 患者编码
        prescriptionNo: this.info.prescriptionNo, // 处方号

        isSettlement: this.info.isSettlement || 0, // 结算类型--是否结算 0.未结算(智互查询，通过处方号和患者编码查询)  1.已结算(HIS查询，通过就诊时间和患者编码查询)
        visitDate: this.info.visitDate
          ? this.common.parseTime(this.info.visitDate, "{y}-{m}-{d}")
          : "", // 就诊时间

        state: /index/.test(this.$route.fullPath) ? 1 : 0, // 0 开方未结束  state=1 开方已结束 首页  
      };
      this.loading = true;
      this.$store.commit("openSpin");
      this.api2("getPrintMedicalRecordsData", obj)
        .then((res) => {
          this.$store.commit("closeSpin");
          this.loading = false;
          if (res.result.length) {
            
            this.recordsData = res.result[0];
            setTimeout(() => {
              if (this.autoPrint) {
                this.$print(this.$refs.print4);
                this.close();
              }
            }, 300);

            // 记录日志用
            res.drugList = this.drugList;
            this.$store.commit("sendLog", {
              patientCode: obj.patientCode,
              apiName: "getPrintMedicalRecordsData",
              inParams: obj,
              outParams: res,
              remarks: `病历打印数据获取成功 处方ID:${obj.prescriptionNo} `,
            });
          } else {
            this.$Message.warning("病历打印数据获取失败");
            this.recordsData = { symptomInfoList: [] };
            this.close();
          }
        })
        .catch((err) => {
          this.loading = false;
          this.recordsData = { symptomInfoList: [] };
          this.close();
        });
    },
    close() {
      this.$emit("input", false);
    },
    init() {
      this.recordsData = { symptomInfoList: [] };
      if (this.value) {
        this.getData();
      }
    },
    print() {
      this.$print(this.$refs.print4);
    }
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" >
.medicalRecordPrint_body {
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  display: none;
  font-size: 15px;

  .ivu-badge{
    margin: 0 10px 10px 0;
    .ivu-badge-body{
      top: -12px !important;
    }
    .ivu-badge-count{
      border-color: #ddd;
    }
  }

  .drugDecoctPattern,
  .drugTakePattern {
    display: none;
  }

  .quantity {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 22px;
    color: #f00;
  }

  .drugs_format {
    font-size: 16px !important;
    line-height: 1.5em !important;
    width: 100%;
    padding-bottom: 10px;

    .drug {
      // min-width: 33%;
      flex: 1;
      white-space: nowrap;
      display: inline-block;
    }
  }

  .hander {
    border-bottom: 1px dotted #b20000;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .checkbox_1 {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fgx {
      padding: 0 10px;
    }

    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid #b20000;
      margin-right: 2px;
      position: relative;

      &.check::after {
        content: " ";
        display: inline-block;
        border: 2px solid #b20000;
        border-width: 0 0 2px 2px;
        width: 10px;
        height: 6px;
        transform: rotate(-40deg);
        position: absolute;
        top: 3px;
        left: 2px;
      }
    }
  }

  .mk_title {
    line-height: 50px;
    font-size: 30px;
    text-align: center;
    color: #b20000;
    font-family: 隶书;
  }

  .u_row {
    line-height: 26px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .u_item {
      display: flex;

      .label {
        color: #b20000;
        font-size: 14px;
        padding-right: 2px;
      }
    }
  }

  .content {
    min-height: 520px;

    .u_row {
      line-height: 24px;
    }

    .label {
      width: 64px;
      flex-shrink: 0;
      text-align: right;
    }

    .cf {

      .value,
      .u_item {
        width: 100%;
        position: relative;
      }
    }
  }

  .foot {
    border-top: 1px dotted #b20000;
    padding-bottom: 10px;

    .value {
      width: 120px;
    }
  }
}

.medicalRecordPrint_show .medicalRecordPrint_body {
  display: block;
  position: relative;
  background: transparent;
}

@media print {
  .medicalRecordPrint_body {
    display: block;
    position: absolute;
  }
}
</style>